<template>
  <div v-if="isReady">
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 pt-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">
            {{ $t('salinity.batch_data_list') }}
          </span>
        </h3>

        <div class="card-toolbar">
          <ul class="nav nav-pills nav-pills-sm nav-dark-75">
            <li>
              <router-link :to="{name: 'salinity.add_batch'}" class="btn btn-sm btn-info">
                {{ $t('GENERAL.add') }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-3 pb-0">
        <!--begin::Table-->
        <div class="table-responsive">
          <table class="table table-borderless table-vertical-center" v-if="batch_data">
            <thead>
              <tr>
                <th class="p-0" style="width: 15%">
                  {{ $t('salinity.batch_name') }}
                </th>
                <th class="p-0" style="width: 50%">
                  {{ $t('salinity.batch_desc') }}
                </th>
                <th class="p-0" style="min-width: 10%">
                  {{ $t('GENERAL.last_update') }}
                </th>
                <th class="p-0 text-right" style="min-width: 10%">
                  {{ $t('GENERAL.option') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, i) in batch_data">
                <tr v-bind:key="i">
                  <td class="pl-0">
                    <a class="text-dark-75">
                      <router-link :to="{name: 'salinity.edit_batch', params: {id: item.id}}">
                        {{ item.name }}
                      </router-link>
                    </a>
                  </td>
                  <td class="pl-0">
                    <a class="text-dark-75">
                      {{ item.description ? item.description : '-' | str_limit(128) }}
                    </a>
                  </td>

                  <td class="pl-0">
                    <div>
                      <a class="text-muted font-weight-bold">
                        {{ item.lu_datetime }}
                      </a>
                    </div>
                  </td>
                  <td class="text-right pr-0">
                    <router-link
                      :to="{name: 'salinity.edit_batch', params: {id: item.id}}"
                      class="btn btn-icon btn-light btn-sm mx-3"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon-->
                        <inline-svg src="media/svg/icons/Communication/Write.svg"></inline-svg>
                        <!--end::Svg Icon-->
                      </span>
                    </router-link>
                    <a class="btn btn-icon btn-light btn-sm" @click="deleteConfirmationModal(item.id)" ref="btnShow">
                      <span class="svg-icon svg-icon-md svg-icon-danger">
                        <!--begin::Svg Icon-->
                        <inline-svg src="media/svg/icons/General/Trash.svg"></inline-svg>
                        <!--end::Svg Icon-->
                      </span>
                    </a>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <div class="text-center" v-else>No Data</div>
        </div>
        <!--end::Table-->
      </div>
      <!--end::Body-->
      <!--end::Advance Table Widget 2-->
    </div>
  </div>

  <div class="card card-custom card-stretch gutter-b" v-else>
    <!--begin::Body-->
    <div class="card-body pt-3 p-20">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import * as Services from '../services.js';

export default {
  name: 'salinity_batch_list',
  data() {
    return {
      isReady: false,
      batch_data: null,
      total_post: 0,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: 'Salinity Data', route: '/admin/salinity'}]);

    this.$store
      .dispatch(Services.GET_BATCH_LIST)
      .then((res) => {
        this.isReady = true;
        this.batch_data = res.data;
        this.total_post = res.data.length;
      })
      .catch((error) => {
        // console.log(error);
      });
  },
  methods: {
    deleteConfirmationModal(id) {
      this.deleteConfirm(() => {
        this.isReady = false;
        // console.log('Deleting post confirmation: ' + id);
        this.$store
          .dispatch(Services.DELETE_BATCH_DATA, {id: id})
          // go to which page after successfully login
          .then((res) => {
            this.$store
              .dispatch(Services.GET_BATCH_LIST)
              // go to which page after successfully login
              .then((res) => {
                this.isReady = true;
                this.batch_data = res.data;
                this.total_post = res.data.length;
              })
              .catch((error) => {
                // console.log(error);
              });
          })
          .catch((error) => {
            // console.log(error);
            // Swal.fire({
            //     title: '',
            //     text: error,
            //     icon: 'error',
            //     confirmButtonClass: 'btn btn-secondary',
            //     heightAuto: false,
            // });
          });
      });
    },
  },
};
</script>

<style></style>
